<template>
  <div id="Homeindex">
    <meta name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0,user-scalable=no" />
    <div class="bac" :style="{ height: windowHeight }">
      <div class="tit_logo">
        <!-- <img style="height: 21px" src="../../image/nn_logo.png" alt="" /> -->
        <!-- <img style="height: 21px" src="../../image/sr_logo.png" alt="" /> -->
      </div>

      <div class="title">
        <van-form class="tit_content">

          <van-field v-model="phone" placeholder="请输入手机号" style="border-radius: 20px;" maxlength="20"
            :rules="[{ required: true, message: '' }]" />
          <!-- <div>{{openid}}</div> -->
          <van-field style="border-radius: 20px;" use-button-slot v-model="remark" placeholder="请输入验证码" maxlength="10"
            :rules="[{ required: true, message: '' }]">
            <van-button style="height: 14.5px;" :disabled="!countdown" slot="button" @click="getCode()" class="add">
              <div v-if="countdown" style="color: #FB9070;font-size:13px ;">{{ text }}</div>

              <div v-if="!countdown" style="color: #FB9070;font-size:13px;">{{ text }} ({{ count }}S)</div>
            </van-button>
          </van-field>

          <div class="submit">
            <!-- <div>立即领取</div> -->
            <!-- <van-button round type="info"  native-type="submit" >立即领取</van-button> -->
            <img @click="btnOk()" src="../../image/btnok.png" alt="" />
          </div>
        </van-form>
        <!--  <div class="support">
         <img style="vertical-align: middle; width: 47px; margin-top: -3px" src="../../image/xmwp_logo.png" alt="" />
          技术支持
        </div> -->
        <!-- <div>111</div> -->

      </div>
      <div class="bottom">
        <div style="font-weight: bold;text-align: center;font-size: 15pt;">领取说明</div>
        <div style="margin-top: 0.9375rem;">1.代金券需在有效期内使用，过期失效；</div>
        <div>2.订单若发生退款，实际退款金额将扣除优惠金额；</div>
        <div>3.在法律允许范围内，本次活动最终解释权归活动方所有。</div>

      </div>
      <div class="signUp" v-show="succShow" style="z-index: 2002">
        <img class="statusImg" style="z-index: 2002" src="../../image/pack.png" alt="" />
        <img class="statusImg1" style="z-index: 2003" src="../../image/get.png" alt="" @click="isOK()" />
        <div class="popOne" style="z-index: 2003;">
          <div style="margin-left: 0.125rem;">恭喜您成功领取</div>
          <!-- <div>满50减20优惠券</div> -->
        </div>

      </div>
      <!-- 遮罩层 -->
      <div class="zhe" v-show="succShow"></div>


      <div class="zhe" @click="isOK()" v-show="failShow"></div>
    </div>
  </div>
</template>
<script>
import {
  Dialog
} from 'vant';
export default {
  name: "Homeindex",

  data() {
    return {
      countdown: true,
      username: "",
      ceshi: '',
      phone: "",
      remark: "",
      timer: null,
      count: "",
      failShow: false, //重复
      succShow: false, //成功
      windowHeight: "",
      text: "发送验证码",
      openid: '',

    };
  },

  components: {
    title() {
      return this.disabled ? `重新获取 ( ${this.second} ) s` : '获取验证码';
    },
  },

  methods: {
    isOK() {
      this.failShow = false;
      this.succShow = false;
    },

    btnOk() {
      this.$request.checkPhone({
        phone: this.phone,
        code: this.remark
      }).then(res => {
        console.log(res)
        if (res.code == 1) {
          // this.ceshi=this.openid
          this.$request.sendCoupon({
            stock_id: res.data.stock_id,
            openid: this.$storage.getLocal('openId'),
            phone: this.phone,
          }).then(res => { //已选商品
            console.log(res)
            if (res.code == 200) {
              this.succShow = true
            } else {
              this.$toast(res.msg);
            }

            // this.name=res.data.data.openid
          })
        } else {
          this.$toast(res.font);
        }

      })

    },
    //操作手机号
    onCallInput: function (event) {
      let value = this.phone.replace(/\D/g, "").substr(0, 11); // 不允许输入非数字字符，超过11位数字截取前11位
      let len = value.length;
      if (len > 3 && len < 8) {
        value = value.replace(/^(\d{3})/g, "$1 ");
      } else if (len >= 8) {
        value = value.replace(/^(\d{3})(\d{4})/g, "$1 $2 ");
      }
      this.phone = value;
    },
    // 提交表单
    // onSubmit(errorInfo) {
    //   this.$request
    //     .logon({
    //       user_name: this.username, //用户名
    //       tel: this.phone.replace(/\s*/g, ""), //手机号
    //       remark: this.remark, //所属分行
    //     })
    //     .then((res) => {
    //       // 登录成功
    //       if (res.code == 0) {
    //         //成功
    //         this.succShow = true;
    //         this.username = "";
    //         this.phone = "";
    //         this.remark = "";
    //       } else if (res.code == 2) {
    //         // 重复签到
    //         this.failShow = true;
    //       } else {
    //         // 失败
    //         this.$toast.fail(res.msg);
    //       }
    //     });
    // },

    getCode() {
      var re = /^1\d{10}$/
      if (this.phone !== '' && re.test(this.phone)) {
        this.$request.sendPhoneCode({
          phone: this.phone,
        }).then(res => {
          console.log(res)
          if (res.code == 1) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.countdown = false;
              this.text = "重新发送"
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.countdown = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$toast('验证码发送失败');
          }

        })

      } else {
        this.$toast('请您输入正确的手机号');
      }

    },


  },
  created() {
    // console.log(this.windowHeight);
    this.openid = this.$storage.getLocal('openId')

    // alert(openid)
  },
  beforeCreate() { },
  mounted() {
    console.log(document.documentElement.clientHeight);
    window.onresize = () => {
      return () => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        this.windowHeight = window.fullHeight; // 高
        this.windowWidth = window.fullWidth; // 宽
      };
    };
  },
  watch: {
    windowHeight(val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
};
</script>

<style scoped>
.tit_logo {
  width: 330px;
  height: 30px;
  margin: 0 auto;
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
}

.van-button__text {
  color: #012192;
}

.bac {
  position: absolute;
  width: 100vw;
  min-height: 785px;
  height: 100vh;
  /* height: 844px; */
  background-image: url(../../image/index_bag.png);
  background-size: 100% 100%;
  /* overflow: auto; */
}

.yuan_out {
  width: 18px;
  height: 18px;
  background: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  position: relative;
}

.yuan {
  width: 7.5px;
  height: 7.5px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
}

.statusImg {
  width: 90%;
  height: 350px;
  border-radius: 10px;
  /* background-color: #fff; */
  position: absolute;
  top: 120px;
  left: 5%;
}

.statusImg1 {
  position: absolute;
  width: 56%;
  height: 2.1875rem;
  left: 22%;
  top: 406px;

}

.van-cell {
  width: 300px;
  background: #ffffff;
  /* border-radius: 5px; */
  margin: auto;
  margin-top: 15px;
}

/* .signUp {
  width: 80%;
  height: 215px;
  border-radius: 10px;
  
  position: absolute;
  top: 270px;
  left: 10%;
} */

.zhe {
  width: 100vw;
  height: 117vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

/* .signUp>img {
  position: absolute;
  width: 72%;
  
  bottom: 95px;
  left: 14%;
  display: block;
  margin: 0 auto;
  overflow: auto;
} */

.support {
  width: 280px;
  margin: 0 auto;
  font-size: 10px;
  color: #fff;
  text-align: center;
  height: 0.78rem;
  position: relative;
  top: 30px;
}

.van-cell::after {
  border: none;
}

.title {
  width: 86%;
  /* position: absolute; */
  margin: 0 auto;
  margin-top: 170px;
  /* top: 33%; */
  background: #1e46b3;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  /* overflow: hidden; */
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tit {
  width: 280px;
  margin: 25px auto;
  height: 18px;
  line-height: 18px;
}

.tit_name {
  height: 35px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 9px;
}

.submit {
  width: 310px;
  height: 53px;
  margin-left: 1.875rem;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 12px;
  /* background: linear-gradient(0deg, #d8b164 0%, #ffffff 100%); */
  border-radius: 50px;
  /* font-size: 18px; */
}

.submit img {
  width: 100%;
  height: 100%;
}

.van-button__text {
  color: white !important;
}

.van-button::before {
  border-radius: 10px !important;
  background: #36B5FC !important;
}

.van-button {
  border-radius: 10px !important;

  /* background: #36B5FC !important; */
}

.van-button__text {
  font-size: 0.9rem;
}

.popOne {
  position: absolute;
  width: 54%;
  height: 2.1875rem;
  left: 30%;
  top: 300px;
  color: #EF3426;
  font-size: 1rem;
}

.popTwo {
  width: 100%;
  color: #999999;
  text-align: center;
  height: 23px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
}

.popSub {
  width: 210px;
  height: 40px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 25px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  border-radius: 20px;
  background: #0A1C40;
}

.bottom {
  width: 90%;
  margin-left: 5%;
  line-height: 1.25rem;
  color: white;
  font-size: 13pt;
  margin-top: 2.725rem;
}

.add {
  bottom: 0.255rem;
  left: 0.625rem;
  height: 0.9375rem;
  border: 0;
  /* color: orange; */
}
</style>

<template>
	<div class="show">
		<show></show>
	</div>
</template>

<script>
	import show from "../components/Home/show.vue"
	export default {
		name:'sh',
		components: {
			show
		}
	}
</script>
<style>

</style>